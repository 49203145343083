<template>
    <tooltip-component :text="name.substring(0, 7)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <message-text-outline />
        </div>
        <textarea-modal
            :name="name"
            label="Comment"
            title="Comment"
            @onConfirmClick="(data, payload) => submit(contentType, payload)"
        />
    </tooltip-component>
</template>

<script>
import MessageTextOutline from 'mdi-vue/MessageTextOutline'
import modalsMixins from '@/mixins/modals/modalsMixins'
import textareaMixins from '@/mixins/modals/textareaMixins'
import TooltipComponent from '@/components/TooltipComponent';
import TextareaModal from '../../../modals/TextareaModal';

export default {
    name: 'ToolbarComment',
    components: {
        MessageTextOutline,
        TooltipComponent,
        TextareaModal
    },
    mixins: [
        modalsMixins,
        textareaMixins
    ],
    data() {
        return {
            name: 'comment-modal'
        }
    }
}
</script>
