import {
    PAY_OUTREACH
} from '@/store/modules/seo/seo-list/action-types.js'
import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList')

export default {
    data() {
        return {
            loading: false
        }
    },
    watch: {
        loading() {
            this.$bus.$emit('setActionLoading', this.loading)
        }
    },
    methods: {
        ...mapSeoListActions([
            PAY_OUTREACH
        ]),
        async submit(contentType) {
            if (this.loading) { return }
            this.loading = true
            switch (contentType) {
            case 'outreach':
                await this.PAY_OUTREACH({
                    checked_items: this.getCheckedElementsId
                }).then(() => {
                    this.$bus.$emit('showSnackBar', 'Your selected blogs were payed', 'success')
                }).catch((err) => {
                    const message = err?.response?.data?.message || 'Error in pay'
                    this.$bus.$emit('showSnackBar', message, 'error')
                }).finally(() => {
                    this.loading = false
                    this.$bus.$emit('setActionLoading', this.loading)
                })
                break
            default:
                break
            }
            this.loading = false
            // this.$emit('get-list')
        }
    }
}
