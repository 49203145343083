<template>
    <div
        v-if="items && items.data && items.data.length"
        class="table-content-wrap"
    >
        <div
            v-for="(row, id) in items.data"
            :id="`row-${id}`"
            :key="id"
            class="custom-table__row"
            :class="{
                classes,
                'is_updated': row.is_updated
            }"
        >
            <component
                :is="components[item.sort_by]"
                v-for="(item, rowId) in rows"
                :id="id"
                :key="rowId"
                :hash="`#row-${id}`"
                :style="item.styles"
                :class="item.classes"
                :date_info_type="item.date_info_type"
                :row="row"
                :start-from="items.from"
                :content-type="contentType"
                :default-checked="row.roles || row.permissions"
                :list="list"
                :value="row[item.sort_by]"
                @action="data => $emit('action', {col: item.sort_by, data})"
            />
        </div>
    </div>
    <content-table-loading v-else-if="loading" />
    <content-table-no-data v-else />
</template>

<script>
import ContentTableNoData from './ContentTableNoData'
import ContentTableLoading from './ContentTableLoading'

export default {
    name: 'ContentTableContent',
    components: {
        ContentTableNoData, ContentTableLoading
    },
    props: {
        items: {
            type: Object
        },
        headerItems: {
            type: Array
        },
        contentType: String,
        list: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: true
        },
        classes: String
    },
    data() {
        return {
            components: {
                subcategories: 'row-sub-category',
                checkbox: 'row-checkbox',
                index: 'row-index',
                id: 'row-name',
                subject: 'row-task-url',
                post_type: 'row-name',
                published: 'row-published',
                active: 'row-published',
                post_slug: 'row-url',
                wordcount: 'row-wordcount',
                words: 'row-wordcount',
                locale: 'row-locale',
                created_at: 'row-created-at',
                updated_at: 'row-updated-at',
                updated_by: 'row-updated-by',
                revisions_count: 'row-revisions',
                is_in_menu: 'row-is-in-menu',
                locked: 'row-locked',
                actions: 'row-actions',
                post_count: 'row-post-count',
                category_name: 'row-category-name',
                name: 'row-name',
                firstname: 'row-name',
                lastname: 'row-user-info',
                email: 'row-email',
                user_permissions: 'row-chips',
                roles: 'row-check-list',
                permissions: 'row-check-list',
                is_active: 'row-locked',
                url_donor: 'row-link-outreach-donor',
                url_acceptor: 'row-link-outreach-acceptor',
                domain: 'row-link',
                anchor_text: 'row-bold',
                executor: 'row-updated-by',
                owner: 'row-updated-by',
                assignee: 'row-tasks-assignee',
                type_payment: 'row-seo-link-type',
                pinged_at: 'row-created-at',
                paid: 'row-paid-at',
                price: 'row-price',
                reviews_count: 'row-reviews',
                action_type: 'row-description',
                table_name: 'row-log-name',
                description: 'row-description',
                log_name: 'row-log-name',
                properties: 'row-properties',
                status: 'row-status',
                deadline_at: 'row-due-date',
                priority: 'row-priority',
                ip: 'row-ip',
                ns: 'row-ns',
                filename: 'row-file-name',
                filetype: 'row-file-type',
                filesize: 'row-file-size',
                cms_status: 'row-published',
                comments: 'row-comments',
                comments_count: 'row-comments',
                user: 'row-updated-by',
                assagnee: 'row-updated-by',
                ping_status: 'row-published'
            },
            hash: '',
            isSaved: false
        }
    },
    computed: {
        rows() {
            return this.headerItems
        }
    },
    watch: {
        '$route.name': {
            handler(newVal, oldVal) {
                if (this.isSaved || newVal === oldVal) {
                    return
                }
                console.log('test1')
                this.$bus.$emit('tableLoading', this.items?.total)
            },
            deep: true
        },
        'items.total': {
            handler(newVal, oldVal) {
                console.log('newVal: ', newVal)
                console.log('oldVal: ', oldVal)
                if (this.isSaved || newVal === oldVal) { return }
                console.log('test2')
                this.$bus.$emit('tableLoading', this.items?.total)
            },
            deep: true
        }
    },
    activated() {
        this.isSaved = false
        console.log('test3')
        this.$bus.$emit('tableLoading', this.items.total)
    },
    deactivated() {
        this.isSaved = true
    }
}
</script>
