<template>
    <tooltip-component
        v-if="getCheckedItems.length === 1"
        :text="'Comments'"
    >
        <div
            class="custom-toolbar__actions-item"
            @click="openCommentsPopup"
        >
            <message-text-outline />
        </div>
        <component
            :is="'the-comments-task'"
            v-if="showPopup"
            :id="getCheckedItems[0].id"
            v-click-outside="hidePopup"
            :comments="getCheckedItems[0].comments"
            @hideComments="hidePopup"
        />
    </tooltip-component>
</template>

<script>
import TheCommentsTask from '@/components/comments/TheCommentsTask.vue';
import MessageTextOutline from 'mdi-vue/MessageTextOutline'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarComment',
    components: {
        MessageTextOutline,
        TooltipComponent,
        TheCommentsTask
    },
    props: {
        data: {}
    },
    data() {
        return {
            showPopup: false
        }
    },
    computed: {
        getCheckedItems() {
            return this.data.filter((item) => item.checked)
        }
    },
    methods: {
        openCommentsPopup() {
            this.showPopup = true
        },
        hidePopup() {
            this.showPopup = false
        }
    }
}
</script>
