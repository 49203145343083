var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination"},[(_vm.pages.length != 0)?_c('ul',{staticClass:"pagination-list"},[(_vm.currentPage >= _vm.visibleStartPoint)?_c('li',{staticClass:"pagination-list__item"},[_c('router-link',{key:_vm.$route.fullPath,staticClass:"pagination-list__link",attrs:{"replace":"","to":{
                    path: _vm.$route.fullPath,
                    query: _vm.pagesInit(1)
                }}},[_vm._v(" "+_vm._s(1)+" ")])],1):_vm._e(),(_vm.lastPage > _vm.visibleStartPoint && _vm.currentPage >= _vm.visibleStartPoint)?_c('li',{staticClass:"pagination-list__item"},[_c('span',{staticClass:"pagination-list__link"},[_vm._v(" ... ")])]):_vm._e(),_vm._l((_vm.displayedPages),function(i){return _c('li',{key:i,staticClass:"pagination-list__item"},[_c('router-link',{key:_vm.$route.fullPath,class:_vm.currentPage === i ? 'pagination-list__link router-link-exact-active' : 'pagination-list__link',attrs:{"replace":"","to":{
                    path: _vm.$route.fullPath,
                    query: _vm.pagesInit(i)
                }}},[_vm._v(" "+_vm._s(i)+" ")])],1)}),(_vm.lastPage > 7 && _vm.currentPage < _vm.pages.length - 1)?_c('li',{staticClass:"pagination-list__item"},[_c('span',{staticClass:"pagination-list__link"},[_vm._v(" ... ")])]):_vm._e(),(_vm.currentPage <= _vm.pages.length + 1)?_c('li',{staticClass:"pagination-list__item"},[_c('router-link',{key:_vm.$route.fullPath,staticClass:"pagination-list__link",class:_vm.currentPage === _vm.lastPage ? 'pagination-list__link router-link-exact-active' : 'pagination-list__link',attrs:{"replace":"","to":{
                    path: _vm.$route.fullPath,
                    query: _vm.pagesInit(_vm.lastPage)
                }}},[_vm._v(" "+_vm._s(_vm.lastPage)+" ")])],1):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }