import { required, email } from 'vuelidate/lib/validators';

export const EmailValidationRules = {
    type_payment: {
        required
    },
    info_payment: {
        required,
        email
    }
}
