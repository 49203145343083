<template>
    <div class="content-table__filters-field content-table__filters-field__date">
        <range-date-picker
            name="pagination page"
            :label="label"
            :to="to"
            :from="from"
            @onDateToSelect="onChangeTo($event)"
            @onDateFromSelect="onChangeFrom($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'DateField',
    props: {
        label: String,
        value: Object
    },
    data() {
        return {
            date: { ...this.value },
            from: null,
            to: null
        }
    },
    watch: {
        value(val) {
            if (!val) {
                this.date = {}
                this.to = null
                this.from = null
                return
            }
            this.date = { ...this.value }
        },
        date() {
            this.to = this.date.to
            this.from = this.date.from
        }
    },
    methods: {
        onChangeTo(event) {
            this.to = event
            this.emitChange()
        },
        onChangeFrom(event) {
            this.from = event
            this.emitChange()
        },
        emitChange() {
            console.log('this.from: ', this.from)
            console.log('this.to: ', this.to)
            if (!this.from || !this.to) { return }
            console.log('test')
            this.$emit('update:field', {
                from: this.from,
                to: this.to
            });
            this.$emit('onChange', {
                from: this.from,
                to: this.to
            })
        }
    }
}
</script>

<style scoped>
    .content-table__filters-field.content-table__filters-field__date {
        width: fit-content;
    }
</style>
