import {
    DELETE_PERMISSIONS,
    DELETE_ROLES,
    ACTION_DELETE_USERS,
    ACTION_GET_USERS_LIST
} from '@/store/modules/permissions/permissions-list/action-types.js'

import {
    ACTION_DELETE_TASK
} from '@/store/modules/tasks/action-types.js'

import {
    ACTION_LANDING_DELETE_POST,
    ACTION_BLOG_DELETE_POST,
    ACTION_ESSAY_DELETE_POST
} from '@/store/modules/content/content-list/action-types.js'

import {
    DELETE_PBN,
    DELETE_OUTREACH,
    ACTION_GET_OUTREACH_LIST,
    ACTION_GET_PBN_LIST
} from '@/store/modules/seo/seo-list/action-types.js'
import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('contentList');

const {
    mapActions: mapPermissionsListActions
} = createNamespacedHelpers('permissionsList')
const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList')

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks')

export default {
    methods: {
        ...mapContentListActions([
            ACTION_LANDING_DELETE_POST,
            ACTION_BLOG_DELETE_POST,
            ACTION_ESSAY_DELETE_POST
        ]),
        ...mapPermissionsListActions([
            DELETE_PERMISSIONS,
            DELETE_ROLES,
            ACTION_DELETE_USERS,
            ACTION_GET_USERS_LIST
        ]),
        ...mapSeoListActions([
            DELETE_PBN,
            DELETE_OUTREACH,
            ACTION_GET_OUTREACH_LIST,
            ACTION_GET_PBN_LIST
        ]),
        ...mapTasksActions([
            ACTION_DELETE_TASK
        ]),
        submit(contentType) {
            switch (contentType) {
            case 'landing':
                this[ACTION_LANDING_DELETE_POST]({
                    posts: this.getCheckedElementsId,
                    query: this.$route.query
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected landings were deleted', 'success')
                    })
                break;
            case 'blog':
                this[ACTION_BLOG_DELETE_POST]({
                    posts: this.getCheckedElementsId,
                    query: this.$route.query
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected blogs were deleted', 'success')
                    })
                break;
            case 'essay':
                this[ACTION_ESSAY_DELETE_POST]({
                    posts: this.getCheckedElementsId,
                    query: this.$route.query
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected essays were deleted', 'success')
                    })
                break;
            case 'permission':
                this.DELETE_PERMISSIONS({
                    items: this.getCheckedElementsId
                })
                break
            case 'roles':
                this.DELETE_ROLES({
                    items: this.getCheckedElementsId
                })
                break
            case 'outreach':
                this.DELETE_OUTREACH({
                    items: this.getCheckedElementsId
                })
                    .then(() => {
                        this.ACTION_GET_OUTREACH_LIST({ ...this.$route.query })
                        this.$bus.$emit('showSnackBar', 'Your selected users were deleted', 'success')
                    })
                break
            case 'pbn':
                this.DELETE_PBN({
                    items: this.getCheckedElementsId
                })
                    .then(() => {
                        this.ACTION_GET_PBN_LIST({ ...this.$route.query })
                        this.$bus.$emit('showSnackBar', 'Your selected users were deleted', 'success')
                    })
                break
            case 'tasks':
                this.ACTION_DELETE_TASK({
                    tasks: this.getCheckedElementsId,
                    query: this.$route.query
                })
                break
            case 'users':
                this.ACTION_DELETE_USERS({
                    users: this.getCheckedElementsId
                })
                    .then(() => {
                        this.ACTION_GET_USERS_LIST({ ...this.$route.query })
                        this.$bus.$emit('showSnackBar', 'Your selected users were deleted', 'success')
                    })
                break;
            default:
                break
            }
        }
    }
}
