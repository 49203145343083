<template>
    <tooltip-component :text="name.substring(0, 8)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <format-list-bulleted />
        </div>
        <category-modal
            :title="'Change Category'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import FormatListBulleted from 'mdi-vue/FormatListBulleted'
import modalsMixins from '@/mixins/modals/modalsMixins'
import categoryMixins from '@/mixins/modals/categoryMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarCategory',
    components: {
        FormatListBulleted,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        categoryMixins
    ],
    data() {
        return {
            name: 'category-modal'
        }
    }
}
</script>
