<template>
    <div class="content-table__filters-field content-table__filters-field__categories">
        <form-select
            ref="select"
            v-model="category.value"
            :field.sync="category.value"
            :items="items"
            label="Categories"
            name="categories"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        >
            <template
                v-slot:list="{ listItems }"
            >
                <ul
                    class="form-select__list"
                >
                    <li
                        v-for="(item, index) in getCategoriesGroup(listItems)"
                        :key="index"
                        style="margin-left: 5px; margin-top: 5px"
                    >
                        <strong>{{ item.text }}:</strong>
                        <ul>
                            <li
                                v-for="(cat, catIndex) in getCategories(listItems, item.categories)"
                                :key="catIndex"
                                class="form-select__list-item"
                                @click="selectItem(cat.value)"
                            >
                                {{ cat.text }}
                            </li>
                        </ul>
                    </li>
                </ul>
            </template>
        </form-select>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapContentCategoryFields } from '@/store/modules/content/content-category'
import { cloneDeep, flatten } from 'lodash'

export default {
    name: 'CategoriesField',
    props: [
        'value', 'errors', 'isValid',
        'disabled', 'contentType'
    ],
    data() {
        return {
            category: {}
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapContentCategoryFields([
            'selectCategories'
        ]),
        items() {
            return flatten(this.selectCategories[this.contentType].map((item) => item.categories))
        }
    },
    watch: {
        value(val) {
            this.category = cloneDeep(this.items.find((item) => +item.value === +val) || {})
        }
    },
    created() {
        this.category = cloneDeep(this.items.find((item) => +item.value === +this.value) || {})
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('onChange', event)
        },
        selectItem(item) {
            this.$refs.select.selectItem(item)
        },
        getCategoriesGroup(list) {
            const ids = list.map((item) => item.value)
            return this.selectCategories[this.contentType].filter((item) => item.categories.find((category) => ids.includes(category.value)))
        },
        getCategories(list, categories) {
            return categories.filter((cat) => list.find((item) => item.value === cat.value))
        }
    }
}
</script>
