<template>
    <TooltipComponent :text="'Complete task'">
        <p
            class="custom-toolbar__actions-item"
            @click="openModal('complete-modal')"
        >
            <CheckOutline />
        </p>
        <confirm-modal
            :title="`Complete task`"
            :content="'Do you want complete task?'"
            name="complete-modal"
            @onConfirmClick="setCompleteTask()"
        />
    </TooltipComponent>
</template>

<script>
import CheckOutline from 'mdi-vue/CheckOutline'
import modalsMixins from '@/mixins/modals/modalsMixins'
import lockMixins from '@/mixins/modals/lockMixins'
import TooltipComponent from '@/components/TooltipComponent';
import {
    ACTION_COMPLETE_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarFinalizeTask',
    components: {
        CheckOutline,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        lockMixins
    ],
    data() {
        return {
            name: 'finalize-modal',
            isValid: true
        }
    },
    computed: {
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        },
        getUser() {
            return this.$store.getters['auth/getterUser']
        },
        showComplete() {
            // const currentUserAssignee = this.getCheckedRow[0].assignee_id === this.getUser.id
            // return this.getCheckedRow.length === 1 && currentUserAssignee
            return true
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_COMPLETE_TASK
        ]),
        async setCompleteTask() {
            try {
                await this.ACTION_COMPLETE_TASK({
                    tasks: this.getCheckedRow.map((item) => item.id)
                })
                this.$bus.$emit('showSnackBar', 'Task was update', 'success')
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
