import { eventBus } from '@/helpers/event-bus'
import ContentLayout from '@/layouts/ContentLayout'
import ContentTable from '@/components/CustomTable/ContentTable'
import ContentFilters from '@/components/CustomTable/ContentFilters'
import ContentToolbar from '@/components/CustomTable/ContentToolbar'
import ContentHiddenModals from '@/components/CustomTable/ContentHiddenModals'
import { mapContentListFields } from '@/store/modules/content/content-list'
import { helperPermissionFilter } from '@/helpers/functions/helperPermissionFilter'
import { mapGetters } from 'vuex'

import CustomTable from '@/components/CustomTable'

export default {
    components: {
        ContentLayout,
        ContentTable,
        ContentFilters,
        ContentToolbar,
        ContentHiddenModals,
        CustomTable
    },
    props: ['contentType'],
    computed: {
        ...mapContentListFields([
            'loading'
        ]),
        ...mapGetters('auth', {
            userPermissions: 'getterUserPermissions'
        }),
        toolbarSettings() {
            return this.allToolbarSettings
                .filter((setting) => helperPermissionFilter(setting, this.userPermissions))
                .map((setting) => setting.name)
        },
        checkRoute() {
            return this.$route.name === this.currentRoute
        },
        checkCurrentSelectedItems() {
            return this.items.data?.filter((item) => item.checked === true).length
        }
        // returnNoEmptyQuries() {
        //     let queries = {};
        //     Object.keys(this.filters).forEach(key => {
        //         if (this.filters[key]) {
        //             queries[key] = this.filters[key]
        //         }
        //     })
        //     return queries;
        // }
    },
    mounted() {
        eventBus.$on('changeRowChecked', ({ rowId }) => {
            if (this.checkRoute) {
                const index = this.items.data.findIndex((d) => d.id === rowId)
                const new_row = this.items.data.find((d) => d.id === rowId)
                if (new_row) new_row.checked = new_row.checked !== true
                if (index !== -1) this.items.data.splice(index, 1, new_row)
            }
        })
        if (Object.values(this.$route.query).length !== 0) {
            this.filters = { ...this.$route.query }
        } else {
            this.filters = { ...this.page_filters }
        }
        if (!this.items || !this.items?.data?.length) {
            this.getList(this.filters)
        }
    },
    activated() {
        this.$router.replace({
            query: this.filters,
            hash: this.$route.hash
        }).catch(() => {})
        const updatedCount = this.items?.data?.replace((acc, item) => (item.is_updated ? acc + 1 : acc), 0) || 0
        if (this.items?.data?.length + updatedCount > this.filters?.page) {
            this.getList(this.filters)
        }
    },
    methods: {
        selectAllCheckbox(event) {
            if (this.checkRoute) {
                this.items.data.forEach((val, id) => {
                    val.checked = event
                    this.items.data.splice(id, 1, val)
                })
            }
        }
    }
}
