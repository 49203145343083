<template>
    <div>
        <div class="custom-table__row custom-table__row-actions custom-table__row-no_data-wrapper">
            <div class="custom-table__row-actions__toolbar custom-table__row-no_data">
                <img
                    src="@/assets/img/not-found.svg"
                    alt=""
                >
                <p>No data found</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComponentTableNoData'
}
</script>

<style lang="scss">
    .custom-table__row-no_data-wrapper {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center !important;
        padding-top: 100px !important;
        padding-bottom: 100px !important;
        .custom-table__row-no_data {
            img {
                margin-left: auto;
                margin-right: auto;
            }
            p {
                text-transform: uppercase;
                color: rgba(175,178,185,.6);
                font-size: 40px;
                font-weight: 900;
                letter-spacing: 2.4px;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
</style>
