<template>
    <tooltip-component
        v-if="showCategory"
        :text="name.substring(0, 8)"
    >
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <format-list-bulleted />
        </div>
        <modal-select
            :title="'Change Category'"
            :name="name"
            :input-label="'Select a category'"
            :content-type="contentType"
            :prepend-value="getCheckedRow.length === 1 ? getCheckedRow[0].post_type : null"
            :list-object="categoryList"
            @changeData="onChangeStatus($event)"
        />
    </tooltip-component>
</template>

<script>
import FormatListBulleted from 'mdi-vue/FormatListBulleted'
import modalsMixins from '@/mixins/modals/modalsMixins'
import TooltipComponent from '@/components/TooltipComponent';
import { mapAdminFields } from '@/store/modules/auth'

import {
    ACTION_CHANGE_CATEGORY_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarTaskCategory',
    components: {
        FormatListBulleted,
        TooltipComponent
    },
    mixins: [
        modalsMixins
    ],
    data() {
        return {
            name: 'category-task-modal',
            selectedPostType: null
        }
    },
    computed: {
        categoryList() {
            return [
                { text: 'blog', value: 'blog' },
                { text: 'essay', value: 'essay' },
                { text: 'landing', value: 'landing' }
            ]
        },
        ...mapAdminFields([
            'user.id'
        ]),
        showCategory() {
            // const checkedList = this.data.filter((item) => item.checked)
            // const checkedListFiltered = checkedList.filter((item) => item.created_by !== this.id)
            // return checkedListFiltered.length === 0

            return true
        },
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_CHANGE_CATEGORY_TASK
        ]),
        async submit() {
            try {
                await this.ACTION_CHANGE_CATEGORY_TASK({
                    tasks: this.getCheckedElementsId,
                    post_type: this.selectedPostType
                })
                this.$bus.$emit('showSnackBar', 'Your task category was changed', 'success')
                this.$emit('get-list')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        onChangeStatus(e) {
            this.selectedPostType = e
        }
    }
}
</script>
