<template>
    <tooltip-component
        v-if="showDeleteInTask"
        class="custom-toolbar__delete"
        :text="name.substring(0, 6)"
    >
        <div
            class="custom-toolbar__delete"
            @click="checkOpen(name)"
        >
            <delete-icon />
        </div>
        <confirm-modal
            :title="`Delete ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import DeleteIcon from 'mdi-vue/Delete'
import modalsMixins from '@/mixins/modals/modalsMixins'
import deleteMixins from '@/mixins/modals/deleteMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarDelete',
    components: {
        DeleteIcon,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        deleteMixins
    ],
    props: {
        contentType: String,
        data: Array
    },
    data() {
        return {
            name: 'delete-modal'
        }
    },
    computed: {
        hasLocked() {
            return this.getCheckedElementsId.reduce((acc, id) => acc || !!this.data.find((item) => item.id === id).locked, false)
        },
        showDeleteInTask() {
            // if (this.contentType === 'tasks') {
            //     if (this.getUserIsAdmin) return true
            //     const checkedTasks = this.data.filter((item) => item.checked)
            //     const tasksOnUser = checkedTasks.filter((item) => item.assignee_id === this.getUserId)

            //     return tasksOnUser.length === checkedTasks.length
            // }
            return true
        },
        getUserIsAdmin() {
            return this.$store.getters['auth/getterUserIsAdmin']
        },
        getUserId() {
            return this.$store.getters['auth/getterUserId']
        }
    },
    methods: {
        checkOpen(name) {
            if (this.hasLocked) {
                this.$bus.$emit('showSnackBar', "You can't delete locked post!", 'error')
                return
            }
            this.openModal(name)
        }
    }
}
</script>
