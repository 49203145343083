<template>
    <tooltip-component :text="name.substring(0, 4)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <lock />
        </div>
        <confirm-modal
            :title="`Lock ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import Lock from 'mdi-vue/Lock'
import modalsMixins from '@/mixins/modals/modalsMixins'
import lockMixins from '@/mixins/modals/lockMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarLock',
    components: {
        Lock,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        lockMixins
    ],
    data() {
        return {
            name: 'lock-modal'
        }
    },
    methods: {
    }
}
</script>
