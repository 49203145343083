<template>
    <div class="content-table__filters-field">
        <form-input
            v-model.number="page"
            name="pagination page"
            legend="Pagination Page"
            placeholder="Pagination Page"
            type="number"
            :field.sync="page"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'SearchField',
    props: {
        value: String
    },
    data() {
        return {
            page: this.value
        }
    },
    watch: {
        value(val) {
            this.page = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>
