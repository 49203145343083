<template>
    <content-layout
        :links="links"
        :class="tableClasses"
    >
        <template
            v-if="options.filters"
            #filters
        >
            <div class="custom-table-filters--wrap">
                <div
                    class="custom-table-filters--header"
                    @click="showFilters = !showFilters"
                >
                    <div>
                        <div class="custom-table-filters--label">
                            <FilterOutline />
                            Filters
                            <span>{{ activeFiltersCount }}</span>
                        </div>
                        <div
                            v-if="total"
                            class="custom-table-total"
                        >
                            Total: <span>{{ total }}</span>
                        </div>
                    </div>
                    <div>
                        <div
                            v-if="hasUpdated"
                            class="updated-label"
                        >
                            Updated
                        </div>
                        <div
                            v-if="activeFiltersCount"
                            class="custom-table-filters-clear"
                            @click.stop="resetFilters"
                        >
                            <Eraser />
                            CLEAR
                        </div>
                        <Arrow
                            class="arrow"
                            :class="{
                                'arrow--show-filters' : showFilters
                            }"
                        />
                    </div>
                </div>
                <div
                    v-if="showFilters"
                    class="custom-table-filters--body"
                >
                    <content-filters
                        id="custom-table-filters"
                        ref="filters"
                        :content-type="contentType"
                        :filters_settings="filter_settings"
                        :filter-items="filter_items"
                        :filters="filters"
                    />
                </div>
            </div>
        </template>
        <template
            v-if="options.content"
            #content
        >
            <div
                v-if="loading || actionLoading"
                class="table-loader"
            />
            <content-table
                :items="items"
                :filters="filters"
                :header-items="headers_items"
                :content-type="contentType"
                :loading="loading || !items"
                :list="list"
                @selectAllCheckbox="selectAllCheckbox($event)"
                @action="data => $emit('action', data)"
            />
            <slot name="customModals" />
        </template>
        <template
            v-if="options.toolbar"
            #toolbar
        >
            <content-toolbar
                v-if="items"
                :toolbar-settings="toolbar_settings"
                :data="items.data"
                :content-type="contentType"
                @get-list="getData"
            />
        </template>
        <template
            v-if="options.hiddenModals"
            #hiddenModals
        >
            <content-hidden-modals
                :content-type="contentType"
                :current-selected="checkCurrentSelectedItems"
            />
        </template>
    </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout'
import ContentTable from '@/components/CustomTable/ContentTable'
import ContentFilters from '@/components/CustomTable/ContentFilters'
import ContentToolbar from '@/components/CustomTable/ContentToolbar'
import ContentHiddenModals from '@/components/CustomTable/ContentHiddenModals'
import FilterOutline from 'mdi-vue/FilterOutline.vue'
import Eraser from 'mdi-vue/Eraser.vue'
import Arrow from '@/icons/Arrow'

export default {
    name: 'CustomTable',
    components: {
        ContentLayout,
        ContentTable,
        ContentFilters,
        ContentToolbar,
        ContentHiddenModals,
        FilterOutline,
        Arrow,
        Eraser
    },
    props: {
        filter_settings: Object,
        filter_items: Array,
        page_filters: Object,
        headers_items: Array,
        toolbar_settings: Array,
        links: Array,
        currentRoute: String,
        loading: Boolean,
        contentType: String,
        items: Object,
        options: Object,
        tableClasses: String,
        filters: Object,
        total: {
            required: false
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            actionLoading: false,
            showFilters: false
        }
    },
    computed: {
        checkCurrentSelectedItems() {
            if (!this.items) return 0
            return this.items.data?.filter((item) => item.checked === true).length
        },
        hasUpdated() {
            return this.items?.data?.some((item) => item.is_updated)
        },
        activeFiltersCount() {
            let count = 0
            Object.keys(this.filters).forEach((key) => {
                if (['sort_by', 'sort_order', 'search_by'].includes(key)) { return }
                if (this.page_filters[key] !== this.filters[key]) {
                    count += 1
                }
            })
            return count
        }
    },
    activated() {
        // eslint-disable-next-line no-return-assign
        this.$bus.$on('setActionLoading', (val) => this.actionLoading = val)
    },
    deactivated() {
        // eslint-disable-next-line no-return-assign
        this.$bus.$off('setActionLoading', (val) => this.actionLoading = val)
    },
    methods: {
        selectAllCheckbox(event) {
            this.$emit('selectAllCheckbox', event)
        },
        getData() {
            this.$emit('getData', this.$route.query)
        },
        resetFilters() {
            this.$router.replace({
                query: this.page_filters
            })
        }
    }
}
</script>

<style scoped lang="scss">
.custom-table-total {
    padding: 0 25px 0;
    display: inline-flex;
    align-items: center;
    span {
        background: #e7f2fa;
        font-weight: 700;
        padding: 10px;
        border-radius: 5px;
        margin-left: 5px;
    }
}
    .custom-table-filters-clear {
        margin-right: 20px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        justify-content: space-between;
        width: 90px;
    }
    .custom-table-filters--label {
        color: #4f5257;
        span:last-of-type {
            padding: 7px 10px;
            background: $round-count;
            color: $orange;
            border-radius: 5px;
        }
    }
    .updated-label{
        margin-right: 25px;
        background: rgba(39, 217, 31, 0.15);
        padding: 15px;
        font-size: 14px;
    }
.table-loader {
    height: 4px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #ddd;
    top: 0;
    left: 0;
}
.table-loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #f44336;
    animation: line-loading 1s linear infinite;
}

@keyframes line-loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}
    .custom-table-filters--wrap{
        padding-bottom: 15px;
        background: #fff;
        margin-bottom: 25px;
    }
    .custom-table-filters--header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px 0;
        cursor: pointer;
        & > div {
            display: flex;
            align-items: center;
        }
    }
    .arrow {
        transform: rotate(-90deg);
        &--show-filters {
            transform: rotate(90deg);
        }
    }
    .updated-label{
        margin-right: 25px;
        background: rgba(39, 217, 31, 0.15);
        padding: 15px;
        font-size: 14px;
    }
</style>
