<template>
    <transition
        v-if="getCheckedRows > 0"
        name="fade"
    >
        <div class="custom-toolbar">
            <toolbar-selected
                :data="data"
            />
            <div class="custom-toolbar__actions">
                <component
                    :is="'toolbar-' + item"
                    v-for="(item, id) in toolbarSettings"
                    :key="id"
                    :data="data"
                    :content-type="contentType"
                    @get-list="$emit('get-list')"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import ToolbarCategory from './components/ToolbarCategory'
import ToolbarComment from './components/ToolbarComment'
import ToolbarDelete from './components/ToolbarDelete'
import ToolbarLock from './components/ToolbarLock'
import ToolbarPing from './components/ToolbarPing'
import ToolbarPlacement from './components/ToolbarPlacement'
import ToolbarPublish from './components/ToolbarPublish'
import ToolbarSelected from './components/ToolbarSelected'
import ToolbarUnpublish from './components/ToolbarUnpublish'
import ToolbarTrash from './components/ToolbarTrash'
import ToolbarUnlock from './components/ToolbarUnlock'
import ToolbarUpdate from './components/ToolbarUpdate'
import ToolbarPriority from './components/ToolbarPriority'
import ToolbarStatus from './components/ToolbarStatus'
import ToolbarTaskCategory from './components/ToolbarTaskCategory'
import ToolbarPay from './components/ToolbarPay'
import ToolbarPaymentType from './components/ToolbarPaymentType'
import ToolbarMassComments from './components/ToolbarMassComments'
import ToolbarTakeTask from './components/ToolbarTakeTask'
import ToolbarFinalizeTask from './components/ToolbarFinalizeTask'
import ToolbarComplete from './components/ToolbarComplete'
import ToolbarCreatePageEssay from './components/ToolbarCreatePageEssay'

export default {
    name: 'Index',
    components: {
        ToolbarCategory,
        ToolbarComment,
        ToolbarDelete,
        ToolbarLock,
        ToolbarPing,
        ToolbarPlacement,
        ToolbarPublish,
        ToolbarSelected,
        ToolbarUnpublish,
        ToolbarTrash,
        ToolbarUnlock,
        ToolbarUpdate,
        ToolbarPriority,
        ToolbarStatus,
        ToolbarTaskCategory,
        ToolbarPay,
        ToolbarPaymentType,
        ToolbarMassComments,
        ToolbarTakeTask,
        ToolbarFinalizeTask,
        ToolbarComplete,
        ToolbarCreatePageEssay
    },
    props: {
        toolbarSettings: Array,
        data: Array,
        contentType: String
    },
    computed: {
        getCheckedRows() {
            return this.data?.filter((item) => item.checked === true).length || 0
        }
    }
}
</script>
