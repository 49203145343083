<template>
    <tooltip-component :text="name.substring(0, 9)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <check />
        </div>
        <placement-modal
            :name="name"
            :content-type="contentType"
            @onConfirmClick="(data, payload) => submit(contentType, payload)"
        />
    </tooltip-component>
</template>

<script>

import Check from 'mdi-vue/Check'
import modalsMixins from '@/mixins/modals/modalsMixins'
import TooltipComponent from '@/components/TooltipComponent';
import placementMixins from '@/mixins/modals/placementMixins'
import PlacementModal from '../../../modals/PlacementModal';

export default {
    name: 'ToolbarPlacement',
    components: {
        PlacementModal,
        Check,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        placementMixins
    ],
    data() {
        return {
            name: 'placement-modal'
        }
    }
}
</script>
