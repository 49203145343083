<template>
    <div class="custom-table">
        <div class="custom-table__content-wrap">
            <div>
                <content-table-header
                    v-if="items"
                    :filters="filters"
                    :data="items.data"
                    :header-items="headerItems"
                    :content-type="contentType"
                    :classes="classes"
                    @selectAllCheckbox="$emit('selectAllCheckbox', $event)"
                />
                <content-table-content
                    :items="items"
                    :header-items="headerItems"
                    :content-type="contentType"
                    :loading="loading"
                    :list="list"
                    :classes="classes"
                    @action="data => $emit('action', data)"
                />
            </div>
        </div>
        <pagination
            v-if="items"
            :last-page="items.last_page"
        />
        <reviews-modal
            :name="'review-modal'"
        />
        <log-modal
            :name="'log-modal'"
            :title="'Log'"
        />
    </div>
</template>

<script>
import ContentTableHeader from './ContentTableHeader'
import ContentTableContent from './ContentTableContent'
import Pagination from '../../Pagination';
import ReviewsModal from '../../modals/ReviewsModal';
import LogModal from '../../modals/LogModal';

export default {
    name: 'ContentTable',
    components: {
        LogModal,
        ContentTableHeader,
        ContentTableContent,
        Pagination,
        ReviewsModal
    },
    props: {
        items: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: true
        },
        headerItems: {
            type: Array
        },
        contentType: String,
        filters: Object,
        list: {
            type: Array,
            default: () => []
        },
        classes: String
    }
}
</script>

<style lang="scss">
    @import "@/assets/styles/common/base/_mixins";
    @import "@/assets/styles/dashboard/components/content/_content-table";
    .content-flex-start {
        justify-content: flex-start !important;
    }
</style>
