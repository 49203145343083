<template>
    <div
        v-if="filters_settings.lists"
        class="content-table__filters-field content-table__filters-field-search-field"
        :class="{ 'content-table__filters-field-search' : filters_settings.config.search }"
    >
        <form-select
            v-model="searchField"
            name="search-field"
            label="Search"
            :field.sync="searchField"
            :items="filters_settings.lists.fieldsList"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'SearchField',
    props: {
        filters_settings: Object,
        value: {}
    },
    data() {
        return {
            searchField: this.value
        }
    },
    watch: {
        value(val) {
            this.searchField = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>
