import {
    ACTION_TRASH
} from '@/store/modules/files/action-types';
import {
    ACTION_ADDITIONS_TRASH
} from '@/store/modules/files-additional/action-types'

import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapFilesActions
} = createNamespacedHelpers('files');

const {
    mapActions: mapFilesAdditionalActions
} = createNamespacedHelpers('filesAdditional');

export default {
    methods: {
        ...mapFilesActions([
            ACTION_TRASH
        ]),
        ...mapFilesAdditionalActions([
            ACTION_ADDITIONS_TRASH
        ]),
        submit(contentType) {
            switch (contentType) {
            case 'files':
                this.ACTION_TRASH({
                    files: this.getCheckedElementsId,
                    query: { ...this.$route.query }
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'The file is successfully trashed', 'success')
                    })
                    .catch((err) => {
                        const message = err?.response?.data?.message || 'Error'
                        this.$bus.$emit('showSnackBar', message, 'error')
                    })
                break;
            case 'files-addition':
                this.ACTION_ADDITIONS_TRASH({
                    files: this.getCheckedElementsId,
                    query: { ...this.$route.query }
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'The file is successfully trashed', 'success')
                    })
                    .catch((err) => {
                        const message = err?.response?.data?.message || 'Error'
                        this.$bus.$emit('showSnackBar', message, 'error')
                    })
                break;
            default:
                break
            }
        }
    }
}
