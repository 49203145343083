<template>
    <tooltip-component :text="name.substring(0, 5)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <block />
        </div>
        <confirm-modal
            :title="`Trash ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
            @onConfirmClick="submit(contentType)"
        />
    </tooltip-component>
</template>

<script>
import Block from '@/icons/Block'
import modalsMixins from '@/mixins/modals/modalsMixins'
import trashMixins from '@/mixins/modals/trashMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarTrash',
    components: {
        Block,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        trashMixins
    ],
    data() {
        return {
            name: 'trash-modal'
        }
    }
}
</script>
