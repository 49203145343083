import {
    ACTION_LANDING_UNLOCK,
    ACTION_BLOG_UNLOCK,
    ACTION_ESSAY_UNLOCK
} from '@/store/modules/content/content-list/action-types.js'

import {
    ACTION_USER_UNLOCK
} from '@/store/modules/permissions/permissions-list/action-types.js'
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('contentList');
const {
    mapActions: mapContentPermissionActions
} = createNamespacedHelpers('permissionsList');

export default {
    methods: {
        ...mapContentListActions([
            ACTION_LANDING_UNLOCK,
            ACTION_BLOG_UNLOCK,
            ACTION_ESSAY_UNLOCK
        ]),
        ...mapContentPermissionActions([
            ACTION_USER_UNLOCK
        ]),
        submit(contentType) {
            switch (contentType) {
            case 'landing':
                this[ACTION_LANDING_UNLOCK]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected landings was unlocked', 'success')
                    })
                break;
            case 'blog':
                this[ACTION_BLOG_UNLOCK]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected blogs was unlocked', 'success')
                    })
                break;
            case 'essay':
                this[ACTION_ESSAY_UNLOCK]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected essays was unlocked', 'success')
                    })
                break;
            case 'users':
                this[ACTION_USER_UNLOCK]({
                    users: this.getCheckedElementsId
                }).then(() => {
                    this.$bus.$emit('showSnackBar', 'Your selected users was unlocked', 'success')
                })
                break;
            default:
                break;
            }
        }
    }
}
