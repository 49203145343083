<template>
    <tooltip-component :text="name.substring(0, 4)">
        <div
            :class="{ loading }"
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <cached />
        </div>
        <confirm-modal
            :title="`Ping ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import Cached from '@/icons/Cached'
import modalsMixins from '@/mixins/modals/modalsMixins'
import pingMixins from '@/mixins/modals/pingMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarPing',
    components: {
        Cached,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        pingMixins
    ],
    props: {
        contentType: String
    },
    data() {
        return {
            name: 'ping-modal'
        }
    }
}
</script>
