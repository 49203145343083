<template>
    <div
        class="content-table__filters-field content-table__filters-field-search-field"
        :class="{ 'content-table__filters-field-search-query': filters_settings.lists }"
    >
        <form-input
            v-model="query"
            name="search-query"
            placeholder="Search"
            prepend-icon="MagnifyIcon"
            :clearable="!!query"
            :field.sync="query"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'SearchQuery',
    props: {
        value: String,
        filters_settings: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            query: this.value
        }
    },
    watch: {
        value(val) {
            this.query = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>

<style lang="scss">
    .content-table__filters-field-search-field {
        width: 70%!important;
    }
    .content-table__filters-field-search {
        margin-right: 0!important;
        width: 30%!important;
        input {
            border-bottom-right-radius: 0!important;
            border-top-right-radius: 0!important;
        }
        &-query {
            input {
                border-bottom-left-radius: 0!important;
                border-top-left-radius: 0!important;
                border-left: none;
            }
        }
    }
</style>
