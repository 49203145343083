<template>
    <tooltip-component :text="name.substring(0, 5)">
        <div
            class="custom-toolbar__actions-item custom-toolbar__actions-item-pencil"
            @click="openModal(name)"
        >
            <Pencil />
        </div>
        <confirm-modal
            :title="`Create essay for ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
            @onConfirmClick="submit(contentType)"
        />
    </tooltip-component>
</template>

<script>
import Pencil from '@/icons/Pencil'
import modalsMixins from '@/mixins/modals/modalsMixins'
import createPageEssayMixins from '@/mixins/modals/createPageEssayMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarTrash',
    components: {
        Pencil,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        createPageEssayMixins
    ],
    data() {
        return {
            name: 'essay-modal'
        }
    }
}
</script>
