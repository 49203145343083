<template>
    <div class="content-table__filters-field">
        <form-select
            v-model="per_page"
            name="per page"
            label="Per Page"
            type="number"
            :field.sync="per_page"
            :items="perPageList"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import { perPageList } from '@/helpers/constants/global-constant'

export default {
    name: 'PerPage',
    props: {
        filters_settings: Object,
        value: String
    },
    data() {
        return {
            per_page: this.value
        }
    },
    computed: {
        perPageList() {
            return perPageList
        }
    },
    watch: {
        value(val) {
            this.per_page = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>
