import {
    PING_OUTREACH,
    PING_PBN
} from '@/store/modules/seo/seo-list/action-types.js'
import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList')

export default {
    data() {
        return {
            loading: false
        }
    },
    watch: {
        loading(val) {
            console.log('this.loading: ', val)
            this.$bus.$emit('setActionLoading', this.loading)
        }
    },
    methods: {
        ...mapSeoListActions([
            PING_OUTREACH,
            PING_PBN
        ]),
        async submit(contentType) {
            if (this.loading) { return }
            this.loading = true
            switch (contentType) {
            case 'outreach':
                await this.PING_OUTREACH({
                    checked_items: this.getCheckedElementsId
                }).then((resp) => {
                    this.$bus.$emit('showSnackBar',
                        `${resp.data.count_success} successful<br>
                        ${resp.data.count_failure} failed`, 'info')
                }).catch((err) => {
                    console.log(err)
                    const message = err?.response?.data?.message || 'Error in pinging'
                    this.$bus.$emit('showSnackBar', message, 'error')
                }).finally(() => {
                    console.log('finally')
                    this.loading = false
                    this.$bus.$emit('setActionLoading', this.loading)
                })
                break
            case 'pbn':
                await this.PING_PBN({
                    items: this.getCheckedElementsId
                }).then((resp) => {
                    this.$bus.$emit('showSnackBar',
                        `Success pinged was: ${resp.data.length} links.
                        Failure pinged was: ${this.getCheckedElementsId.length - resp.data.length} links`, 'info')
                }).catch((err) => {
                    const message = err?.response?.data?.message || 'Error in pinging'
                    this.$bus.$emit('showSnackBar', message, 'error')
                }).finally(() => {
                    this.loading = false
                    this.$bus.$emit('setActionLoading', this.loading)
                })
                break;
            default:
                break
            }
        }
    }
}
