<template>
    <modal-layout
        v-if="dialog"
        :size="'large'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ getLogName }} {{ title }}</h2>
        </template>
        <template #content>
            <slot name="content">
                <vue-json-pretty
                    :path="'res'"
                    :data="jsonString"
                />
            </slot>
        </template>
        <template #buttons>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Ok
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'LogModal',
    components: {
        VueJsonPretty
    },
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        title: String,
        content: String,
        name: String
    },
    computed: {
        getLogName() {
            return this.data.row.log_name
        },
        jsonString() {
            return JSON.parse(this.data.row.properties)
        }
    }
}
</script>

<style scoped>

</style>
