<template>
    <form
        class="content-table__filters"
        @submit.prevent="submit"
    >
        <div style="display: inline-flex">
            <search-field
                v-if="hasSearchBy"
                v-model="filterData.search_by"
                :field.sync="filterData.search_by"
                :filters_settings="filters_settings"
            />
            <search-query
                v-if="hasSearchFor"
                v-model="filterData.search_for"
                :field.sync="filterData.search_for"
                :filters_settings="filters_settings"
            />
        </div>
        <component
            :is="filter.name"
            v-for="(filter, id) in wrapFilterItems"
            :key="id"
            v-model="filterData[filter.value]"
            :label="filter.label || filter.value"
            :field.sync="filterData[filter.value]"
            :filters_settings="filters_settings"
            :content-type="contentType"
        />
        <custom-button
            default
            class="blue-button"
            type="submit"
        >
            UPDATE
        </custom-button>
    </form>
</template>

<script>
import Status from './components/Status'
import SearchField from './components/SearchField'
import CategoriesField from './components/CategoriesField'
import DateField from './components/DateField'
import SearchQuery from './components/SearchQuery'
import UsersFilter from './components/UsersFilter'
import PerPage from './components/PerPage'
import PaginationPage from './components/PaginationPage'
import RangeDatePicker from '../../common/form-elements/RangeDatePicker';
import FormDatePicker from '../../common/form-elements/FormDatePicker';

export default {
    name: 'Index',
    components: {
        Status,
        SearchField,
        CategoriesField,
        DateField,
        SearchQuery,
        PerPage,
        PaginationPage,
        RangeDatePicker,
        FormDatePicker,
        UsersFilter
    },
    props: {
        filters_settings: Object,
        filterItems: Array,
        contentType: String,
        filters: Object
    },
    data() {
        return {
            filterData: {}
        }
    },
    computed: {
        wrapFilterItems() {
            return this.filterItems.filter((item) => ![
                'search_by',
                'search_for'
            ].includes(item.value))
        },
        hasSearchBy() {
            return !!this.filterItems.find((item) => item.value === 'search_by')
        },
        hasSearchFor() {
            return !!this.filterItems.find((item) => item.value === 'search_for')
        }
    },
    watch: {
        '$route.query.page': function (val) {
            if (val) {
                this.filterData.page = val.toString()
                this.setQueries()
            }
        },
        '$route.query.search_for': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.search_for = val
        },
        '$route.query.search_status': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.search_status = val
        },
        '$route.query.status': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.status = val
        },
        '$route.query.per_page': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.per_page = val
        },
        '$route.query.search_by': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.search_by = val
        },
        '$route.query.sort_order': function (val) {
            this.filterData = {
                ...this.filterData,
                sort_by: this.$route.query.sort_by,
                sort_order: val,
                page: '1'
            }
        },
        '$route.query.user_id': function (val) {
            console.log('user_id:', val)
            if (val) this.filterData.page = '1'
            this.filterData.user_id = val
        },
        '$route.query.category_id': function (val) {
            if (val) this.filterData.page = '1'
            this.filterData.category_id = val
        },
        '$route.query.to': function (val) {
            if (val) {
                this.filterData.page = '1'
                return
            }
            this.filters.from = null
            this.filters.to = null
            this.filterData.from = null
            this.filterData.to = null
            this.filters.created_at = ''
            this.filterData.created_at = ''
        },
        '$route.query.from': function (val) {
            if (val) {
                this.filterData.page = '1'
                return
            }
            this.filters.from = null
            this.filters.to = null
            this.filterData.from = null
            this.filterData.to = null
            this.filters.created_at = ''
            this.filterData.created_at = ''
        }
    },
    mounted() {
        this.setFilters()
    },
    activated() {
        this.setFilters()
    },
    methods: {
        setFilters() {
            if (this.filters.created_at) {
                this.filters.from = this.filters.created_at.from
                this.filters.to = this.filters.created_at.to
            }
            this.filterData = {
                ...this.filterData,
                ...this.$route.query,
                ...this.filters,
                created_at: {
                    from: this.filters.from,
                    to: this.filters.to
                }
            }
        },
        submit() {
            this.pushQueries()
            this.setQueries()
        },
        setQueries() {
            this.filterData = {
                ...this.filterData,
                ...this.filters,
                ...this.$route.query
            }
        },
        pushQueries() {
            this.$router.replace({
                query: {
                    ...this.returnNoEmptyQuries({ ...this.filterData })
                },
                hash: this.$route.hash
            }).catch(() => {})
        },
        returnNoEmptyQuries(filters) {
            const queries = {};
            Object.keys(filters).forEach((key) => {
                if (key === 'created_at' && filters[key].from && filters[key].to) {
                    queries.from = filters[key].from
                    queries.to = filters[key].to
                    return
                }
                if (filters[key]) {
                    queries[key] = filters[key]
                }
            })
            return queries;
        }
    }
}
</script>

<style lang="scss">
.content-table__filters {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    & > * {
        margin-top: 25px;
    }
    &-field {
        margin-right: 12px;
        width: 20%;

    }
    .form-datepicker {
        height: 48px !important;
        max-height: unset !important;
        margin-right: 12px;
        width: 300px;
        position: relative;
        &__legend {
            text-transform: capitalize;
        }
        & ~ & {
            &:before {
                content: '-';
                position: absolute;
                left: -12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &__fieldset {
            height: 48px;
            max-height: unset;
            border: none;
            input {
                height: 44px;
                border: none;
            }
        }
    }
    input[name='search-query']{
        &:focus{
            border-left-color: transparent !important;
        }
    }
}

</style>
