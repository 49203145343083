<template>
    <div
        v-if="modals.length"
        :style="{'left': left + 'px'}"
        class="hidden-modals__wrapper"
    >
        <div class="hidden-modals">
            <div
                v-for="(modal, id) in modals"
                :key="id"
                class="hidden-modals__block"
                @click="unfoldingModal(modal, id)"
            >
                <launch /> {{ modal.name }}
            </div>
        </div>
    </div>
</template>
<script>
import { eventBus } from '@/helpers/event-bus'
import Launch from 'mdi-vue/Launch'

export default {
    name: 'ContentHiddenModals',
    components: {
        Launch
    },
    props: {
        contentType: String,
        currentSelected: Number
    },
    data() {
        return {
            modals: [],
            left: 330
        }
    },
    watch: {
        currentSelected(value) {
            if (this.$route.name === `posts-list-${this.contentType}`) {
                if (value === 0) {
                    this.modals = []
                }
            }
        }
    },
    mounted() {
        eventBus.$on('openModal', (modalName) => {
            if (this.$route.name === `posts-list-${this.contentType}`) {
                const index = this.modals.findIndex((modal) => modal.name === modalName)
                if (index !== -1) this.modals.splice(index, 1)
            }
        })
        eventBus.$on('hideModal', (modalName) => {
            if (this.$route.name === `posts-list-${this.contentType}`) {
                this.modals.push({
                    name: modalName,
                    contentType: this.contentType
                })
            }
        })
        eventBus.$on('toggleSidebar', (val) => {
            val ? this.left = 330 : this.left = 30
        })
    },
    methods: {
        unfoldingModal(modal, id) {
            eventBus.$emit('unfoldingModal', modal)
            this.modals.splice(id, 1)
        }
    }
}
</script>

<style lang="scss">
.hidden-modals__wrapper {
    position: fixed;
    bottom: 100px;
    .hidden-modals {
        display: flex;
        &__block {
            display: block;
            padding: 10px 20px;
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
            margin-right: 10px;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            svg {
                width: 16px;
                height: 16px
            }
        }
    }
}
</style>
