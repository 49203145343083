import {
    ACTION_LANDING_PUBLISH,
    ACTION_BLOG_PUBLISH,
    ACTION_ESSAY_PUBLISH
} from '@/store/modules/content/content-list/action-types.js'
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('contentList');

export default {
    methods: {
        ...mapContentListActions([
            ACTION_LANDING_PUBLISH,
            ACTION_BLOG_PUBLISH,
            ACTION_ESSAY_PUBLISH
        ]),
        submit(contentType) {
            switch (contentType) {
            case 'landing':
                this[ACTION_LANDING_PUBLISH]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected landings was published', 'success')
                    })
                break;
            case 'blog':
                this[ACTION_BLOG_PUBLISH]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected blogs was published', 'success')
                    })
                break;
            case 'essay':
                this[ACTION_ESSAY_PUBLISH]({
                    posts: this.getCheckedElementsId
                })
                    .then(() => {
                        this.$bus.$emit('showSnackBar', 'Your selected essays was published', 'success')
                    })
                break;
            default:
                break;
            }
        }
    }
}
