<template>
    <tooltip-component
        v-if="showToolbar"
        :text="'take task'"
    >
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <ClipboardPlayOutline />
        </div>
        <deadline-modal
            :title="`Take ${getCheckedRows} tasks`"
            :content="'Are you sure'"
            :name="name"
            :date="getCheckedRow[0].deadline_at"
            :content-type="contentType"
            @setDeadline="takeTask"
        />
    </tooltip-component>
</template>

<script>
import ClipboardPlayOutline from 'mdi-vue/ClipboardPlayOutline'
import modalsMixins from '@/mixins/modals/modalsMixins'
import lockMixins from '@/mixins/modals/lockMixins'
import TooltipComponent from '@/components/TooltipComponent';
import DeadlineModal from '@/components/modals/DeadlineModal'
import {
    ACTION_TAKE_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarLock',
    components: {
        ClipboardPlayOutline,
        TooltipComponent,
        DeadlineModal
    },
    mixins: [
        modalsMixins,
        lockMixins
    ],
    data() {
        return {
            name: 'start-modal'
        }
    },
    computed: {
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        },
        getUser() {
            return this.$store.getters['auth/getterUser']
        },
        showToolbar() {
            // const taskNotAssignee = this.getCheckedRow[0].assignee_id === null
            // return this.getCheckedRow.length === 1 && taskNotAssignee
            return true
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_TAKE_TASK
        ]),
        async takeTask(date) {
            try {
                await this.ACTION_TAKE_TASK({
                    id: this.getCheckedRow[0].id,
                    deadline_at: date,
                    user: this.getUser
                })
                this.$bus.$emit('showSnackBar', 'Task was update', 'success')
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
