<template>
    <tooltip-component
        v-if="showFinalize"
        :text="'Finalize task'"
    >
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <CheckAll />
        </div>
        <finalize-modal
            :title="`Final ${getCheckedRows} tasks`"
            :content="'Are you sure'"
            :name="name"
            :prepend_data="getCheckedRow[0].post_links"
            :prepend_type="getCheckedRow[0].post_type"
            :is-valid="isValid"
            :content-type="contentType"
            @setFinalize="setFinalize"
        />
    </tooltip-component>
</template>

<script>
import CheckAll from 'mdi-vue/CheckAll'
import modalsMixins from '@/mixins/modals/modalsMixins'
import lockMixins from '@/mixins/modals/lockMixins'
import TooltipComponent from '@/components/TooltipComponent';
import FinalizeModal from '@/components/modals/FinalizeModal'
import {
    ACTION_FINALIZE_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarFinalizeTask',
    components: {
        CheckAll,
        TooltipComponent,
        FinalizeModal
    },
    mixins: [
        modalsMixins,
        lockMixins
    ],
    data() {
        return {
            name: 'finalize-modal',
            isValid: true
        }
    },
    computed: {
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        },
        getUser() {
            return this.$store.getters['auth/getterUser']
        },
        showFinalize() {
            // const currentUserAssignee = this.getCheckedRow[0].assignee_id === this.getUser.id
            // return this.getCheckedRow.length === 1 && currentUserAssignee
            return true
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_FINALIZE_TASK
        ]),
        async setFinalize(data) {
            try {
                await this.ACTION_FINALIZE_TASK({
                    id: this.getCheckedRow[0].id,
                    post_type: this.getCheckedRow[0].post_type,
                    post_links: data.post_links
                })
                this.$bus.$emit('showSnackBar', 'Task was update', 'success')
                data.callback()
            } catch (error) {
                this.isValid = false
                console.log(error.errors);
            }
        }
    }
}
</script>
