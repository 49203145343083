<template>
    <tooltip-component :text="name.substring(0, 6)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <unlock />
        </div>
        <confirm-modal
            :title="`Unlock ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import Unlock from 'mdi-vue/LockOpen'
import modalsMixins from '@/mixins/modals/modalsMixins'
import unlockMixins from '@/mixins/modals/unlockMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarUnlock',
    components: {
        Unlock,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        unlockMixins
    ],
    data() {
        return {
            name: 'unlock-modal'
        }
    },
    methods: {

    }
}
</script>
