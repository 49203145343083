import { required, url } from 'vuelidate/lib/validators';

export const UrlValidationRules = {
    type_payment: {
        required
    },
    info_payment: {
        required,
        url
    }
}
