<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>Placement</h2>
        </template>
        <template #content>
            <div class="alert-wrapper">
                <ul class="alert-wrapper__list">
                    <li class="alert-wrapper__list--item">
                        Make sure the content is original or has been rephrased
                    </li>
                    <li class="alert-wrapper__list--item">
                        Provide the url of the page where the content is about to be published
                    </li>
                </ul>
            </div>
            <form-input
                v-model="$v.url.$model"
                :field.sync="$v.url.$model"
                class="placement-input"
                legend="URL of site where the content is placed"
                name="placement"
                placeholder="URL of site where the content is placed"
                :errors="validationMessage($v.url)"
                :is-valid="$v.url.$dirty && !$v.url.$anyError"
                @input.native="$v.url.$touch()"
                @blur.native="$v.url.$touch()"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="validate"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import { urlValidator } from '@/services/seoLinksService'

import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/Placement/Rules'
import { formMessages } from '@/validation/Placement/Messages'

export default {
    name: 'PlacementModal',
    mixins: [
        modalsEventBusMixins,
        validationMixin,
        formValidationMixin
    ],
    props: {
        name: String
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            url: ''
        }
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.url = ''
                this.$v.$reset()
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        submit() {
            this.confirmModal(this.name, { url: this.url })
            this.text = ''
            this.$v.$reset()
        },
        async validateUrl(url) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    try {
                        await urlValidator(url)
                        resolve()
                    } catch (e) {
                        reject(e)
                    }
                }, 500)
            })
        }
    }
}
</script>

<style lang="scss">
    .alert-wrapper {
        background-color: #2196f3 !important;
        border-color: #2196f3 !important;
        border-radius: 4px;
        margin-bottom: 16px;
        padding: 16px;
        &__list {
            &--item {
                color: #fff;
                font-size: 14px;
                padding-left: 16px;
                line-height: 22px;
                position: relative;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #fff;
                }
            }
        }
    }
    .placement-input {
        .form-input__input {
            padding: 10px !important;
        }
    }
</style>
