<template>
    <div class="custom-toolbar__counter">
        <div class="custom-toolbar__counter-item">
            Selected {{ getCheckedRows }} {{ contentType }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ToolbarSelected',
    props: {
        data: Array,
        contentType: String
    },
    computed: {
        getCheckedRows() {
            return this.data.filter((item) => item.checked === true).length
        }
    }
}
</script>
