<template>
    <tooltip-component :text="tooltipText">
        <div
            :class="{ loading, disabled }"
            class="custom-toolbar__actions-item"
            @click="openPayModal(name)"
        >
            <credit-card-outline />
        </div>
        <confirm-modal
            :title="`Pay ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import CreditCardOutline from 'mdi-vue/CreditCardOutline'
import modalsMixins from '@/mixins/modals/modalsMixins'
import payMixins from '@/mixins/modals/payMixins'
import TooltipComponent from '@/components/TooltipComponent'

export default {
    name: 'ToolbarPay',
    components: {
        CreditCardOutline,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        payMixins
    ],
    props: {
        contentType: String
    },
    data() {
        return {
            name: 'pay-modal'
        }
    },
    computed: {
        hasNotPaymentType() {
            return !this.data.filter((item) => this.getCheckedElementsId.includes(item.id))
                .reduce((acc, item) => acc && item.info_payment && item.type_payment, true)
        },
        wasPaying() {
            return this.data.filter((item) => this.getCheckedElementsId.includes(item.id))
                .reduce((acc, item) => acc || !!item.paid, false)
        },
        disabled() {
            return this.hasNotPaymentType || this.wasPaying
        },
        tooltipText() {
            if (this.wasPaying) {
                return 'One or more link already was payed!'
            }
            if (this.hasNotPaymentType) {
                return 'You cannot pay for link type without payment type!'
            }
            return 'Pay'
        }
    },
    methods: {
        openPayModal(name) {
            if (this.loading || this.disabled) { return }
            this.openModal(name)
        }
    }
}
</script>

<style scoped>

</style>
