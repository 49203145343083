import {
    CHANGE_PAYMENT_TYPE
} from '@/store/modules/seo/seo-list/action-types.js'
import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapSeoListActions
} = createNamespacedHelpers('seoList')

export default {
    data() {
        return {
            loading: false
        }
    },
    watch: {
        loading() {
            this.$bus.$emit('setActionLoading', this.loading)
        }
    },
    methods: {
        ...mapSeoListActions([
            CHANGE_PAYMENT_TYPE
        ]),
        async submit(contentType) {
            if (this.loading || this.disabled) { return }
            await this.$v.$touch()
            if (this.$v.$invalid || this.$v.$anyError) {
                this.$bus.$emit('showSnackBar', 'Some data is invalid!', 'error')
                return
            }
            this.loading = true
            switch (contentType) {
            case 'outreach':
                this.$bus.$emit('closeModal', 'payment-type-modal')
                await this.CHANGE_PAYMENT_TYPE({
                    id: this.getCheckedElementsId[0],
                    type_payment: this.type_payment,
                    info_payment: this.info_payment
                }).then(() => {
                    this.$bus.$emit('showSnackBar', 'Your selected links were changed payment type', 'success')
                }).catch((err) => {
                    const message = err?.response?.data?.message || 'Error in changed payment type'
                    this.$bus.$emit('showSnackBar', message, 'error')
                }).finally(() => {
                    this.loading = false
                    this.$bus.$emit('setActionLoading', this.loading)
                })
                break
            default:
                break
            }
            this.loading = false
            // this.$emit('get-list')
        }
    }
}
