<template>
    <div class="content-table__filters-field">
        <form-select-status
            v-model="status"
            :value="status"
            :field.sync="status"
            :items="filters_settings.lists.statusList"
            name="status"
            label="Status"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'Status',
    props: {
        filters_settings: Object,
        value: {}
    },
    data() {
        return {
            status: this.value
        }
    },
    watch: {
        value(val) {
            this.status = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>
