<template>
    <div class="custom-toolbar__actions-item">
        <refresh />
    </div>
</template>

<script>
import Refresh from 'mdi-vue/Refresh'
import modalsMixins from '@/mixins/modals/modalsMixins'

export default {
    name: 'ToolbarUpdate',
    components: {
        Refresh
    },
    mixins: [
        modalsMixins
    ]
}
</script>
