<template>
    <!-- header -->
    <div
        v-if="filters"
        id="table-head"
        class="custom-table__row custom-table__title"
        :class="classes"
    >
        <div
            v-for="(item, id) in items"
            :key="id"
            :style="item.styles"
            :class="item.classes"
        >
            <div
                v-if="item.sort_by === 'checkbox'"
                class="custom-table__row-info-container"
            >
                <div class="custom-table__row-checkbox">
                    <form-checkbox
                        :value="checkAllStatus"
                        color="$sidebar-orange"
                        @input="selectAllCheckbox"
                    />
                </div>
            </div>
            <div
                v-else-if="item.sort_by === 'index'"
                class="custom-table__row-index"
            >
                #
            </div>
            <div
                v-else
                class="custom-table__thead-title"
                :class="{
                    'custom-table__thead-title__asc' : isCurrentSort(item) && filters.sort_order === 'ASC',
                    'custom-table__thead-title__desc' : isCurrentSort(item) && filters.sort_order === 'DESC'
                }"
                @click="changeSortBy(item.sort_by, item.hide_sort)"
            >
                <h3>
                    {{ item.title }}
                </h3>
                <arrow
                    v-if="!item.hide_sort"
                    class="custom-table__thead-title__arrow"
                    :class="{
                        'custom-table__thead-title__arrow-asc' : isCurrentSort(item) && filters.sort_order === 'ASC',
                        'custom-table__thead-title__arrow-desc' : isCurrentSort(item) && filters.sort_order === 'DESC'
                    }"
                />
            </div>
        </div>
    </div>
    <!-- header -->
</template>

<script>
import Arrow from '@/icons/Arrow'
import eventsMixin from '@/mixins/content/eventsMixin'

export default {
    name: 'ContentTableHeader',
    components: {
        Arrow
    },
    mixins: [
        eventsMixin
    ],
    props: {
        data: {
            type: Array
        },
        headerItems: {
            type: Array
        },
        contentType: String,
        filters: Object,
        classes: String
    },
    data() {
        return {
            items: this.headerItems,
            checkAllStatus: false
        }
    },
    watch: {
        data: {
            deep: true,
            handler(value) {
                const res = value.filter((obj) => obj.checked !== true)
                if (res.length === 0) {
                    this.checkAllStatus = true
                    return
                }
                this.checkAllStatus = false
            }
        }
    },
    methods: {
        selectAllCheckbox() {
            this.checkAllStatus = !this.checkAllStatus
            this.$emit('selectAllCheckbox', this.checkAllStatus)
        },
        changeSortBy(val, prevent) {
            if (prevent) { return }
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    page: 1,
                    sort_by: val === 'user' ? 'user_id' : val,
                    sort_order: this.filters.sort_order === 'ASC' ? 'DESC' : 'ASC'
                },
                hash: this.$route.hash
            }).catch(() => {})
        },
        isCurrentSort(item) {
            return this.filters.sort_by === 'user_id' ? item.sort_by === 'user' : this.filters.sort_by === item.sort_by
        }
    }
}
</script>
