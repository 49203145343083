<template>
    <tooltip-component :text="name.substring(0, 7)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <play />
        </div>
        <confirm-modal
            :title="`Publish ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :content-type="contentType"
        />
    </tooltip-component>
</template>

<script>
import Play from 'mdi-vue/PlayCircle'
import modalsMixins from '@/mixins/modals/modalsMixins'
import publishMixins from '@/mixins/modals/publishMixins'
import TooltipComponent from '@/components/TooltipComponent';

export default {
    name: 'ToolbarPublish',
    components: {
        Play,
        TooltipComponent
    },
    mixins: [
        modalsMixins,
        publishMixins
    ],
    data() {
        return {
            name: 'publish-modal'
        }
    }
}
</script>
