<template>
    <tooltip-component :text="name.substring(0, 6)">
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <list-status />
        </div>
        <modal-select
            :title="'Change Status'"
            :name="name"
            :input-label="'Select a status'"
            :content-type="contentType"
            :list-object="statuses"
            :prepend-value="getCheckedRow.length === 1 ? getCheckedRow[0].status - 1 : null"
            :type="'status'"
            @changeData="onChangeStatus($event)"
        />
    </tooltip-component>
</template>

<script>
import ListStatus from 'mdi-vue/ListStatus'
import modalsMixins from '@/mixins/modals/modalsMixins'
import TooltipComponent from '@/components/TooltipComponent';
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'

import {
    ACTION_CHANGE_STATUS_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarStatus',
    components: {
        ListStatus,
        TooltipComponent
    },
    mixins: [
        modalsMixins
    ],
    data() {
        return {
            name: 'status-modal',
            selectedStatus: null
        }
    },
    computed: {
        ...mapTaskFields([
            'statuses'
        ]),
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_CHANGE_STATUS_TASK
        ]),
        async submit() {
            try {
                await this.ACTION_CHANGE_STATUS_TASK({
                    tasks: this.getCheckedElementsId,
                    status: this.selectedStatus + 1
                })
                this.$bus.$emit('showSnackBar', 'Your task status was changed', 'success')
                this.$emit('get-list')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        onChangeStatus(e) {
            this.selectedStatus = e
        }
    }
}
</script>
