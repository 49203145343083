<template>
    <modal-layout
        v-if="dialog"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="cancelModal()"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <form-date-picker
                v-model="deadline_at"
                label="Due date"
                tabindex="0"
                :min_hours="1"
                :in-future="true"
                :in-max-dealine="true"
                :field.sync="deadline_at"
                :value="deadline_at"
                :prepend-value="date"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                class="orange-button"
                @on-btn-click="setDeadline"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="cancelModal(name)"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'

export default {
    name: 'DeadlineModal',
    mixins: [
        modalsEventBusMixins
    ],
    props: {
        title: String,
        name: String,
        contentType: String,
        date: {}
    },
    data() {
        return {
            deadline_at: null
        }
    },
    methods: {
        setDeadline() {
            this.$emit('setDeadline', this.deadline_at)
            this.confirmModal()
        }
    }
}
</script>
