<template>
    <tooltip-component :text="tooltipText">
        <div
            :class="{ loading, disabled }"
            class="custom-toolbar__actions-item"
            @click="openPaymentModal(name)"
        >
            <credit-card-multiple-outline />
        </div>
        <empty-modal
            :title="`Change payment type ${getCheckedRows} items`"
            :content="'Are you sure'"
            :name="name"
            :prevent-hide="true"
            :content-type="contentType"
        >
            <template #content>
                <div style="margin-bottom: 20px; width: 100%">
                    <form-select
                        v-model="$v.type_payment.$model"
                        name="status"
                        label="Status"
                        :items="paymetTypeList"
                        :errors="validationMessage($v.type_payment)"
                        :is-valid="$v.type_payment.$dirty && !$v.type_payment.$anyError"
                    />
                </div>
                <form-input
                    v-model="$v.info_payment.$model"
                    legend="info_payment"
                    placeholder="info_payment"
                    :errors="validationMessage($v.info_payment)"
                    :is-valid="$v.info_payment.$dirty && !$v.info_payment.$anyError"
                />
            </template>
        </empty-modal>
    </tooltip-component>
</template>

<script>
import CreditCardMultipleOutline from 'mdi-vue/CreditCardMultipleOutline'
import modalsMixins from '@/mixins/modals/modalsMixins'
import changePaymentTypeMixin from '@/mixins/modals/changePaymentTypeMixin'
import TooltipComponent from '@/components/TooltipComponent'
import EmptyModal from '@/components/modals/EmptyModal'
import { EmailValidationRules } from '@/validation/CreateSeoLink/ChangePaymentTypeEmailRulse';
import { UrlValidationRules } from '@/validation/CreateSeoLink/ChangePaymentTypeUrlRulse';
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { formMessages } from '@/validation/CreateSeoLink/Messages'

export default {
    name: 'ToolbarPay',
    components: {
        CreditCardMultipleOutline,
        TooltipComponent,
        EmptyModal
    },
    mixins: [
        modalsMixins,
        changePaymentTypeMixin,
        formValidationMixin,
        validationMixin
    ],
    props: {
        contentType: String
    },
    validations() {
        return this.type_payment === 'paypal_email' ? { ...EmailValidationRules } : { ...UrlValidationRules }
    },
    data() {
        return {
            name: 'payment-type-modal',
            paymetTypeList: [
                {
                    text: 'Paypal invoice',
                    value: 'paypal_invoice'
                },
                {
                    text: 'Paypal email',
                    value: 'paypal_email'
                }
            ],
            type_payment: 'paypal_invoice',
            info_payment: ''
        }
    },
    computed: {
        moreThenOne() {
            return this.getCheckedElementsId.length !== 1
        },
        wasPaying() {
            return this.data.filter((item) => this.getCheckedElementsId.includes(item.id))
                .reduce((acc, item) => acc || !!item.paid, false)
        },
        disabled() {
            return this.moreThenOne || this.wasPaying
        },
        tooltipText() {
            if (this.wasPaying) {
                return 'You can not change payment type for paying link'
            }
            if (this.moreThenOne) {
                return 'You can not change payment type for more then one link!'
            }
            return 'Payment type'
        }
    },
    watch: {
        info_payment() {
            if (this.info_payment.includes(' ')) {
                this.info_payment = this.info_payment.replace(/\s/g, '')
            }
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        openPaymentModal(name) {
            if (this.loading || this.disabled) { return }
            this.openModal(name)
        }
    }
}
</script>

<style scoped>

</style>
