<template>
    <div class="pagination">
        <ul
            v-if="pages.length != 0"
            class="pagination-list"
        >
            <li
                v-if="currentPage >= visibleStartPoint"
                class="pagination-list__item"
            >
                <router-link
                    :key="$route.fullPath"
                    replace
                    :to="{
                        path: $route.fullPath,
                        query: pagesInit(1)
                    }"
                    class="pagination-list__link"
                >
                    {{ 1 }}
                </router-link>
            </li>
            <li
                v-if="lastPage > visibleStartPoint && currentPage >= visibleStartPoint"
                class="pagination-list__item"
            >
                <span class="pagination-list__link">
                    ...
                </span>
            </li>
            <li
                v-for="i in displayedPages"
                :key="i"
                class="pagination-list__item"
            >
                <router-link
                    :key="$route.fullPath"
                    replace
                    :to="{
                        path: $route.fullPath,
                        query: pagesInit(i)
                    }"
                    :class="currentPage === i ? 'pagination-list__link router-link-exact-active' : 'pagination-list__link'"
                >
                    {{ i }}
                </router-link>
            </li>
            <li
                v-if="lastPage > 7 && currentPage < pages.length - 1"
                class="pagination-list__item"
            >
                <span class="pagination-list__link">
                    ...
                </span>
            </li>
            <li
                v-if="currentPage <= pages.length + 1"
                class="pagination-list__item"
            >
                <router-link
                    :key="$route.fullPath"
                    replace
                    :to="{
                        path: $route.fullPath,
                        query: pagesInit(lastPage)
                    }"
                    class="pagination-list__link"
                    :class="currentPage === lastPage ? 'pagination-list__link router-link-exact-active' : 'pagination-list__link'"
                >
                    {{ lastPage }}
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    props: {
        lastPage: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            pages: [],
            visibleStartPoint: 7,
            visibleMiddlePoint: 4
        }
    },
    computed: {
        displayedPages() {
            if (this.currentPage >= 1 && this.currentPage < this.visibleStartPoint) {
                return this.pages.slice(0, this.visibleStartPoint)
            }
            return this.pages.slice(this.currentPage - this.visibleMiddlePoint, this.currentPage + 1)
        },
        currentPage() {
            return +this.$route.query.page || 1
        }
    },
    watch: {
        '$route.query.page': function () {
            this.scrollTo('#custom-table-filters')
        },
        lastPage: {
            handler() {
                this.pages = []
                this.setPages()
            }
        }
    },
    created() {
        this.setPages()
    },
    mounted() {
        this.calculatSize()
    },
    methods: {
        pagesInit(i) {
            return {
                ...this.$route.query,
                page: i
            }
        },
        setPages() {
            for (let i = 1; i <= this.lastPage - 1; i += 1) {
                this.pages.push(i)
            }
        },
        changePage(page) {
            this.$router.push({
                to: this.$route.fullPath,
                query: this.pagesInit(page)
            })
        },
        calculatSize() {
            if (window.screen.width <= 414 && window.screen.width > 375) {
                this.visibleStartPoint = 3
                this.visibleMiddlePoint = 2
            } else if (window.screen.width <= 375 && window.screen.width > 320) {
                this.visibleStartPoint = 2
                this.visibleMiddlePoint = 1
            } else if (window.screen.width <= 320) {
                this.visibleStartPoint = 2
                this.visibleMiddlePoint = 0
            } else {
                this.visibleStartPoint = 7
            }
        }
    }
}
</script>
<style lang="scss">
    .pagination {
        margin: 40px auto 0 auto;
        &-list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            &__item {
                border: 1px solid #e7ecf4;
                margin-right: 10px;
                border-radius: 4px;
                background: transparent;
                display: flex;
                transition: .2s;
                width: 34px;
                height: 34px;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                border: none;

                &:hover {
                    background-color: $orange;
                    border: none;
                }
            }
            &__link {
                color: #888;
                font-size: 14px;
                line-height: 34px;
                font-weight: 400;
                text-align: center;
                width: 34px;
                height: 34px;
                cursor: pointer;
                transition: .2s;
                border-radius: 4px;
                &.router-link-exact-active, &:hover {
                    background-color: $orange;
                    color: #fff;
                }

                &.next, &.prev {
                    width: 100%;
                    height: 100%;
                    mask-position: center;
                    mask-size: 12px 12px;
                    mask-repeat: no-repeat;
                    background: #888;

                    &:hover {
                    background: #fff;
                    border: none;
                }
                }

                &.next {
                    mask-image: url('/images/svg/next.svg');
                }
                &.prev {
                    mask-image: url('/images/svg/prev.svg');
                }
            }
        }
    }
    .disable {
        visibility: hidden;
    }
</style>
