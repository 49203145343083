<template>
    <tooltip-component
        v-if="showPriority"
        :text="name.substring(0, 8)"
    >
        <div
            class="custom-toolbar__actions-item"
            @click="openModal(name)"
        >
            <priority-high />
        </div>
        <modal-select
            :title="'Change Priority'"
            :name="name"
            :input-label="'Select a priority'"
            :content-type="contentType"
            :list-object="priorities"
            :prepend-value="getCheckedRow.length === 1 ? getCheckedRow[0].priority - 1 : null"
            :type="'priority'"
            @changeData="onChangeStatus($event)"
        />
    </tooltip-component>
</template>

<script>
import PriorityHigh from 'mdi-vue/PriorityHigh'
import modalsMixins from '@/mixins/modals/modalsMixins'
import TooltipComponent from '@/components/TooltipComponent';
import { mapTaskFields } from '@/store/modules/content/content-edit/tasks/index.js'
import { mapAdminFields } from '@/store/modules/auth'
import {
    ACTION_CHANGE_PRIORITY_TASK
} from '@/store/modules/tasks/action-types.js'

import { createNamespacedHelpers } from 'vuex'

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'ToolbarPriority',
    components: {
        PriorityHigh,
        TooltipComponent
    },
    mixins: [
        modalsMixins
    ],
    data() {
        return {
            name: 'priority-modal',
            selectedPriority: null
        }
    },
    computed: {
        ...mapTaskFields([
            'priorities'
        ]),
        ...mapAdminFields([
            'user.id'
        ]),
        showPriority() {
            // const checkedList = this.data.filter((item) => item.checked)
            // const checkedListFiltered = checkedList.filter((item) => item.created_by !== this.id)
            // return checkedListFiltered.length === 0
            return true
        },
        getCheckedRow() {
            return this.data.filter((item) => item.checked)
        }
    },
    methods: {
        ...mapTasksActions([
            ACTION_CHANGE_PRIORITY_TASK
        ]),
        async submit() {
            try {
                await this.ACTION_CHANGE_PRIORITY_TASK({
                    tasks: this.getCheckedElementsId,
                    priority: this.selectedPriority + 1
                })
                this.$bus.$emit('showSnackBar', 'Your task priority was changed', 'success')
                this.$emit('get-list')
            } catch (error) {
                this.$bus.$emit('showSnackBar', error, 'error')
            }
        },
        onChangeStatus(e) {
            this.selectedPriority = e
        }
    }
}
</script>
