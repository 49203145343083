<template>
    <div
        class="content-table__filters-field"
        style="width: 20%"
    >
        <form-select-user
            v-model="searchField"
            :field.sync="searchField"
            :init-value="+searchField"
            :label="label"
            name="User"
            @input="onChange"
        />
    </div>
</template>

<script>
export default {
    name: 'UsersFilter',
    props: {
        value: {
            required: false
        },
        label: String
    },
    data() {
        return {
            searchField: this.value
        }
    },
    watch: {
        value(val) {
            this.searchField = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event);
            this.$emit('onChange', event)
        }
    }
}
</script>

<style scoped>

</style>
